import { Box, Button, Chip, Tabs, Stack, Tab, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import LockIcon from '@mui/icons-material/Lock';
import CloseReportIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import palette from '../../ui/palette';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';
import { getApplicationById, sendApplicantInvite, updateApplicant, updateApplication } from '../../services/application.service';
import { IApplicant, IReport } from '../../models/report';
import { RouteUrl } from '../../routes/types';
import { APPLICATION_STATUS, ReportAction, applicantLabelStyles } from '../Reports/constants';
import FlagDialog from '../../components/modals/reports/FlagDialog';
import { getUser } from '../../store/user/selector';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../store/ui/actions';
import moment from 'moment';
import { IOSSwitch } from '../../components/switch';
import { ReactComponent as IncomeReportSvg } from '../../illustrations/images/income_report.svg';
import BankIncomeMode from './components/BankIncomeMode';
import PaystubMode from './components/PaystubMode';
import W2Mode from './components/W2Mode';
import ContractMode from './components/ContractMode';
import DefaultVertificationMode from './components/DefaultVertificationMode';
import CloseReportDialog from '../../components/modals/reports/CloseReportDialog';
import PaystubModeV2 from './components/PaystubModeV2';

export default function ApplicantDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const params = useParams();
  const applicationId = params['id'];
  const [application, setApplication] = useState<any>(null);
  const [reportAction, setReportAction] = useState<string | null>(null);

  const handleGetReportDetail = async () => {
    if (applicationId) {
      const { data } = await getApplicationById(applicationId);
      setApplication(data);
    }
  };

  useEffect(() => {
    handleGetReportDetail();
  }, [applicationId]);

  const handleBackToReports = () => {
    navigate(`${RouteUrl.Reports}?status=${['active', 'flagged', 'pending'].indexOf(application.status) >= 0 ? 'active' : application.status}`);
  };

  const handleCloseReportActionDialog = () => {
    setReportAction(null);
    handleGetReportDetail();
  };

  const handleAddFlag = async (issue: string) => {
    if (application) {
      const response = await updateApplication({
        application_id: application.application_id,
        status: 'flagged',
        flag_issue: issue,
        flagged: true,
        flagged_by: user.name,
      });
      if (response?.status === 'success') {
        dispatch(
          notify({
            severity: 'success',
            open: true,
            message: 'The Application Has Been Flagged',
          })
        );
      }
      handleCloseReportActionDialog();
    }
  };

  const handleCloseReport = async (reason: string) => {
    if (application) {
      const response = await updateApplication({
        application_id: application.application_id,
        status: 'closed',
        decision: reason,
        closed_by: user.name,
        closed_on: new Date(),
      });
      if (response?.status === 'success') {
        dispatch(
          notify({
            severity: 'success',
            open: true,
            message: 'Report Has Been Closed',
          })
        );
      }
      handleCloseReportActionDialog();
    }
  };

  const renderActionDialogs = () => {
    if (reportAction === ReportAction.ADD_FLAG) {
      return <FlagDialog onSubmit={handleAddFlag} onClose={handleCloseReportActionDialog} />;
    }
    if (reportAction === ReportAction.CLOSE_REPORT) {
      return <CloseReportDialog onSubmit={handleCloseReport} onClose={handleCloseReportActionDialog} />;
    }

    return null;
  };

  const downloadAllReports = () => {
    window.open(application.appZip, '_blank');
  };

  return (
    <Box>
      <Box component="header">
        <Stack flexDirection="row" alignItems="center" sx={{ cursor: 'pointer', mb: 3 }} onClick={handleBackToReports}>
          <ChevronLeftIcon />
          <Typography>Back to reports</Typography>
        </Stack>
        {application && (
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
            <Stack flexDirection="row" alignItems="center" gap={3}>
              <Typography variant="h2">Application #{application.application_number}</Typography>
              <Chip
                label={APPLICATION_STATUS[application.status]}
                sx={{
                  borderRadius: 1,
                  ...(applicantLabelStyles[application.status] ?? {}),
                }}
              />
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={2}>
              {application.appZip ? (
                <Button
                  title="Download Documents"
                  sx={{ backgroundColor: palette.primary['10'], color: palette.primary['30'], minWidth: 'fit-content', p: 0.5 }}
                  onClick={downloadAllReports}
                >
                  <FileDownloadOutlinedIcon />
                </Button>
              ) : (
                <></>
              )}
              {application.status !== 'flagged' ? (
                <Button
                  title="Flag Report"
                  sx={{ backgroundColor: palette.primary['10'], color: palette.primary['30'], minWidth: 'fit-content', p: 0.5 }}
                  onClick={() => setReportAction(ReportAction.ADD_FLAG)}
                >
                  <OutlinedFlagIcon />
                </Button>
              ) : (
                <></>
              )}
              {application.status !== 'closed' ? (
                <Button
                  title="Close Report"
                  sx={{ backgroundColor: palette.primary['10'], color: palette.primary['30'], minWidth: 'fit-content', p: 0.5 }}
                  onClick={() => setReportAction(ReportAction.CLOSE_REPORT)}
                >
                  <CloseReportIcon />
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
        )}
      </Box>
      {application && <ReportDetailContent application={application} refresh={handleGetReportDetail} />}
      {renderActionDialogs()}
    </Box>
  );
}

function ReportDetailContent({ application, refresh }: { application: IReport; refresh: any }) {
  const [selectedApplicant, setSelectedApplicant] = useState<IApplicant | null>(null);
  const dispatch = useDispatch();

  const applicants = useMemo(() => {
    const head = application.applicants.filter(a => a.role === 'Head of Household')!!;
    const guarantor = application.applicants.filter(a => a.role === 'Guarantor');
    const cosigner = application.applicants.filter(a => a.role === 'Non-Resident Co-sign');
    const others = application.applicants.filter(a => ['Guarantor', 'Head of Household', 'Non-Resident Co-sign', 'Family Member - Minor'].indexOf(a.role) < 0);
    let arr: any[] = [];
    if (head && head.length) arr = arr.concat(head);
    if (others && others.length) arr = arr.concat(others);
    if (cosigner && cosigner.length) arr = arr.concat(cosigner);
    if (guarantor && guarantor.length) arr = arr.concat(guarantor);
    return arr;
  }, [application.applicants]);
  const isMultipleApplicants = applicants.length > 1;

  useEffect(() => {
    if (!selectedApplicant && applicants?.length > 0) {
      setSelectedApplicant(applicants[0]);
    } else if (selectedApplicant) {
      setSelectedApplicant(applicants.find(a => a._id === selectedApplicant._id)!!);
    }
  }, [applicants]);

  const updateIncomeReq = async (val: boolean) => {
    await updateApplicant(selectedApplicant!!._id, { 'applicants.$.require_income_verification': val });
    refresh();
  };

  return (
    <>
      {/* Check report has multiple applications */}
      <Box sx={{ mb: 3 }}>
        {isMultipleApplicants && (
          <Tabs
            value={selectedApplicant?._id}
            onChange={(_e, value) => {
              const applicant = applicants.find(applicant => applicant._id === value);
              if (applicant) {
                setSelectedApplicant(applicant);
              }
            }}
          >
            {applicants.map(applicant => (
              <Tab key={applicant._id} value={applicant._id} label={applicant.name} sx={{ borderBottom: '1px solid #D4D7DC' }} />
            ))}
          </Tabs>
        )}
      </Box>
      {selectedApplicant && (
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column', width: '40%' }}>
            <Stack gap={2} sx={{ border: 1, borderColor: palette.grey['50'], borderRadius: 2, p: 3, color: palette.text.secondary }}>
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
                <Typography variant="h5" color="text.primary" fontWeight={500}>
                  Applicant Information
                </Typography>
                {(selectedApplicant.role === 'Head of Household' || !selectedApplicant.role) && <Chip color="primary" label="Primary" />}
                {selectedApplicant.role &&
                  ['Guarantor', 'Head of Household', 'Non-Resident Co-sign', 'Family Member - Minor'].indexOf(selectedApplicant.role) < 0 && (
                    <Chip color="primary" label="Secondary" />
                  )}
                {selectedApplicant.role === 'Guarantor' && <Chip color="primary" label="Guarantor" />}
                {selectedApplicant.role === 'Non-Resident Co-sign' && <Chip color="primary" label="Co-Signer" />}
              </Stack>

              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">Application Name</Typography>
                <Typography variant="body1">{selectedApplicant.name}</Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">Email</Typography>
                <Typography variant="body1">{selectedApplicant.email}</Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">Phone Number</Typography>
                <Typography variant="body1">{selectedApplicant.phone}</Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">Application Date</Typography>
                <Typography variant="body1">
                  {selectedApplicant.application_date ? moment(selectedApplicant.application_date).format('MM/DD/YY') : ''}
                </Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">Invite Link</Typography>
                <Typography
                  variant="body1"
                  sx={{ cursor: 'pointer', color: '#6078EA' }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://${window.location.host.replace('dashboard', 'app')}/${application.property_code}/verification?token=${selectedApplicant.token}`
                    );
                    dispatch(
                      notify({
                        severity: 'success',
                        open: true,
                        message: 'Link Copied',
                      })
                    );
                  }}
                >
                  Copy Link
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ cursor: 'pointer', color: '#6078EA' }}
                  onClick={async () => {
                    await sendApplicantInvite(application._id, selectedApplicant._id);
                    dispatch(
                      notify({
                        severity: 'success',
                        open: true,
                        message: 'Invite Sent',
                      })
                    );
                  }}
                >
                  Resend Invite
                </Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">Invite Sent</Typography>
                <Typography variant="body1">
                  {selectedApplicant.last_invite_sent ? moment(selectedApplicant.last_invite_sent).format('MM/DD/YY') : ''}
                </Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">Require Income Verification?</Typography>
                <IOSSwitch
                  checked={selectedApplicant.require_income_verification}
                  onChange={(e, val) => {
                    updateIncomeReq(val);
                  }}
                  disabled={
                    !selectedApplicant.role ||
                    selectedApplicant.role === 'Head of Household' ||
                    selectedApplicant.role === 'Guarantor' ||
                    selectedApplicant.role === 'Non-Resident Co-sign'
                  }
                />
              </Stack>
              {(!selectedApplicant.role || selectedApplicant.role === 'Head of Household') && (
                <Stack gap={1} flexDirection="row" alignItems="center">
                  <LockIcon />
                  <Typography variant="caption">This setting is locked since we always require income verification for primary applicants.</Typography>
                </Stack>
              )}
              {(selectedApplicant.role === 'Guarantor' || selectedApplicant.role === 'Non-Resident Co-sign') && (
                <Stack gap={1} flexDirection="row" alignItems="center">
                  <LockIcon />
                  <Typography variant="caption">This setting is locked since we always require income verification for Guarantors and Co-Signers.</Typography>
                </Stack>
              )}
            </Stack>
            <IncomeReport selectedApplicant={selectedApplicant} application={application} />
          </Box>
          <Stack gap={2} sx={{ border: 1, borderColor: palette.grey['50'], borderRadius: 2, p: 3, color: palette.text.secondary, width: '30%' }}>
            <Typography variant="h5" color="text.primary" sx={{ mb: 1 }} fontWeight={500}>
              Rental Information
            </Typography>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1">Property</Typography>
              <Typography variant="body1">{application.property_name}</Typography>
            </Stack>
            {/* <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1">Floor Plan</Typography>
              <Typography variant="body1">2 bedroom x 2 bath</Typography>
            </Stack> */}
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1">Unit Number</Typography>
              <Typography variant="body1">{application.unit}</Typography>
            </Stack>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1">Applied on</Typography>
              <Typography variant="body1">{application.application_date ? moment(application.application_date).format('MM/DD/YY') : ''}</Typography>
            </Stack>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1">Rent</Typography>
              <Typography variant="body1">{application.monthly_rent ? `$${application.monthly_rent}` : ''}</Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              flex: 1,
              border: !(['active', 'closed', 'flagged'].indexOf(application.status) >= 0 && selectedApplicant.verification_attempts.length === 0) ? 1 : 0,
              borderColor: palette.grey['50'],
              borderRadius: 2,
              p: 3,
              color: palette.text.secondary,
              width: '30%',
            }}
          >
            {!(['active', 'closed', 'flagged'].indexOf(application.status) >= 0 && selectedApplicant.verification_attempts.length === 0) ? (
              <>
                <Typography variant="h5" color="text.primary" sx={{ mb: 1 }} fontWeight={500}>
                  Timeline
                </Typography>
                <Timeline
                  position="right"
                  sx={{
                    '& .MuiTimelineConnector-root': {
                      border: '1px dashed #2C366B',
                      width: '1px',
                      background: 'transparent',
                    },
                    '& .MuiTimelineItem-root': {
                      '& .MuiTimelineDot-root': {
                        width: '24px',
                        border: 'unset',
                        p: 0,
                        my: 1.5,
                      },
                      '&::before': {
                        display: 'none',
                      },
                    },
                  }}
                >
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot variant="outlined">
                        <CheckCircleOutlineRoundedIcon sx={{ width: '24px', color: palette.primary[80] }} />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography color="text.primary">Applied to Property</Typography>
                      <Typography variant="caption">
                        {application.application_date ? moment(application.application_date).format('MMM DD, YYYY') : ''}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  {selectedApplicant.verification_attempts.map((att, index) => (
                    <>
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot variant="outlined">
                            <CheckCircleOutlineRoundedIcon sx={{ width: '24px', color: palette.primary[80] }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography color="text.primary">Attempt {index + 1} | Income Verification</Typography>
                          <Typography variant="caption">{moment(att).format('MMM DD, YYYY')}</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </>
                  ))}
                  {selectedApplicant.verification_attempts.length ? (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot variant="outlined">
                          <CheckCircleOutlineRoundedIcon sx={{ width: '24px', color: palette.primary[80] }} />
                        </TimelineDot>
                        {/* <TimelineConnector /> */}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography color="text.primary">Income Report Made</Typography>
                        <Typography variant="caption">
                          {moment(selectedApplicant.verification_attempts[selectedApplicant.verification_attempts.length - 1]).format('MMM DD, YYYY')}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ) : (
                    <></>
                  )}
                </Timeline>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

function IncomeReport({ selectedApplicant, application }: { selectedApplicant: IApplicant; application: IReport }) {
  const [selectedVerificationIndex, setSelectedVerificationIndex] = useState(selectedApplicant.verification_method.length - 1);

  useEffect(() => {
    setSelectedVerificationIndex(selectedApplicant.verification_method.length - 1);
  }, [selectedApplicant]);

  if (selectedApplicant.income_history.length && selectedApplicant.require_income_verification) {
    return (
      <Stack gap={2} sx={{ border: 1, borderColor: palette.grey['50'], borderRadius: 2, p: 3, color: palette.text.secondary }}>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" color="text.primary" fontWeight={500}>
            Applicant Income Report
          </Typography>
        </Stack>
        {selectedApplicant.verification_method?.length > 1 && (
          <Tabs
            value={selectedVerificationIndex}
            onChange={(_e, value) => {
              setSelectedVerificationIndex(value);
            }}
          >
            {selectedApplicant.verification_method?.map((verificationMethod, index) => (
              <Tab
                key={selectedApplicant._id + verificationMethod + index}
                value={index}
                label={verificationMethodLabels[verificationMethod] ?? verificationMethod}
                sx={{ borderBottom: '1px solid #D4D7DC', flex: 1, textTransform: 'initial' }}
              />
            ))}
          </Tabs>
        )}
        <IncomeReportContent application={application} selectedApplicant={selectedApplicant} verificationMethodIndex={selectedVerificationIndex} />
      </Stack>
    );
  }

  if (selectedApplicant.require_income_verification) {
    return (
      <Stack gap={2} sx={{ border: 1, borderColor: palette.grey['50'], borderRadius: 2, p: 3, color: palette.text.secondary }}>
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
          <Typography variant="h5" color="text.primary" fontWeight={500}>
            Applicant Income Report
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 1.5 }}>
          <IncomeReportSvg />
          <Typography variant="body1">
            Waiting on their
            <br />
            income report
          </Typography>
        </Box>
      </Stack>
    );
  }

  return <></>;
}

function IncomeReportContent({
  selectedApplicant,
  application,
  verificationMethodIndex,
}: {
  selectedApplicant: IApplicant;
  application: IReport;
  verificationMethodIndex: number;
}) {
  if (selectedApplicant.verification_method[verificationMethodIndex] === 'bank_income')
    return <BankIncomeMode selectedApplicant={selectedApplicant} application={application} verificationMethodIndex={verificationMethodIndex} />;
  if (
    selectedApplicant.verification_method[verificationMethodIndex] === 'paystub' ||
    selectedApplicant.verification_method[verificationMethodIndex] === 'payroll'
  )
    return <PaystubMode selectedApplicant={selectedApplicant} application={application} verificationMethodIndex={verificationMethodIndex} />;
  if (selectedApplicant.verification_method[verificationMethodIndex] === 'w2')
    return <W2Mode selectedApplicant={selectedApplicant} application={application} verificationMethodIndex={verificationMethodIndex} />;
  if (selectedApplicant.verification_method[verificationMethodIndex] === '1099')
    return <ContractMode selectedApplicant={selectedApplicant} application={application} verificationMethodIndex={verificationMethodIndex} />;
  if (selectedApplicant.verification_method[verificationMethodIndex] === 'Document Upload')
    return <PaystubModeV2 selectedApplicant={selectedApplicant} application={application} verificationMethodIndex={verificationMethodIndex} />;

  return <DefaultVertificationMode selectedApplicant={selectedApplicant} application={application} verificationMethodIndex={verificationMethodIndex} />;
}

const verificationMethodLabels: Record<string, string> = {
  paystub: 'Paystub',
  bank_income: 'Bank Link',
  w2: 'W2',
  '1099': '1099',
};
