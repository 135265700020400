import moment from 'moment';

export function formatApplication(applicantion: any) {
  return {
    ...applicantion,
    verification_attempts: applicantion.applicants
      .filter((a: any) => a.require_income_verification)
      .reduce((t: number, p: any) => t + p.verification_attempts.length, 0),
    has_cosigner: applicantion.has_cosigner ? 'Yes' : 'No',
    application_date: applicantion.application_date ? moment(applicantion.application_date).format('MM.DD.YYYY') : '',
    last_invite_sent: applicantion.last_invite_sent ? moment(applicantion.last_invite_sent).format('MM.DD.YYYY') : '',
    verification_completed: applicantion.verification_completed ? moment(applicantion.verification_completed).format('MM.DD.YYYY') : '',
    closed_on: applicantion.closed_on ? moment(applicantion.closed_on).format('MM.DD.YYYY') : '',
    verification_methods: applicantion.applicants
      .map((a: any) => a.verification_method[a.verification_method.length - 1])
      .filter((value: any, index: any, array: any) => array.indexOf(value) === index),
    total_monthly_income: applicantion.total_monthly_income ? `$${applicantion.total_monthly_income.toFixed(2)}` : '',
    monthly_rent: applicantion.monthly_rent ? `$${applicantion.monthly_rent}` : '',
    income_level: applicantion.income_level ? `${applicantion.income_level}x` : '-',
    reports_generated: `${applicantion.applicants.filter((a: any) => a.income_history.length > 0 && a.require_income_verification).length}/${applicantion.applicants.filter((a: any) => a.require_income_verification).length}`,
  };
}

export function getTruncateName(name: string) {
  if (!name) return name;

  const splitNames = name.split(' ');
  if (splitNames.length === 1) return name;

  return splitNames[0] + ' ' + splitNames[1].charAt(0) + '...';
}
